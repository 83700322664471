<template>
  <div class="activity-page">
    <h1 class="activity-page__title mc">Roobee Activity</h1>
    <ul class="activity-page__list mc">
      <li>1. Join chat by clicking the button below</li>
      <li>2. Every day we will post in a random time wallet seed phrases</li>
      <li>3. Wallets will contain bROOBEE or BNB</li>
      <li>4. Equal chances to win for everybody!</li>
    </ul>
    <a href="https://t.me/roobee_chat" target="_blank" class="activity-page__btn mc">JOIN CHAT</a>
  </div>
</template>

<script>
export default {
  name: "activity"
}
</script>
