const WalletModule = {
    state: {
        provider: null,
        address: null,
        isLoading: false,
        connectModalShowed: false
    }
}

export default WalletModule;
