<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__inner">
        <a target="_blank" href="https://www.bscscan.com/address/0xe64f5cb844946c1f102bd25bbd87a5ab4ae89fbe" class="footer__link">ROOBEE Contract</a>
        <a target="_blank" href="https://exchange.pancakeswap.finance/#/add/0xE64F5Cb844946C1F102Bd25bBD87a5aB4aE89Fbe/ETH" class="footer__link">PancakeSwap</a>
        <a target="_blank" href="https://burgerswap.org/?type=1&from=BNB&to=bROOBEE" class="footer__link">BurgerSwap</a>
        <a target="_blank" href="https://github.com/roobee-platform" class="footer__link">Github</a>
        <a target="_blank" href="https://t.me/roobee_invest" class="footer__link">Telegram</a>
        <a target="_blank" href="https://medium.com/@roobee_invest" class="footer__link">Medium</a>
        <a target="_blank" href="https://twitter.com/Roobee_invest" class="footer__link">Twitter</a>
        <a target="_blank" href="https://roobee.gitbook.io/roobee-eng/" class="footer__link">Gitbook</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>
