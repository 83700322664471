<template>
  <div class="info info--pt">
    <div class="container">
      <div class="info__title">Farming</div>
      <div class="info__block">
        <div class="info__about">
          <div class="info__title info__title--small">What is Roobee?</div>
          <div class="info__about-text">Roobee (ROOBEE) is a blockchain-based investment platform that allows users to invest in products from both the traditional and crypto markets. The platform is under active development and is already operational. Behind the platform is a team of developers who won prizes in hackathons, including the 2017 and 2019 ETHWaterloo hackathons held in Canada by ETHGlobal. The Roobee community already has 400,000 users from all over the world. 
          </div>
          <div class="info__about-text">The mission of Roobee is to give any person with any level income an opportunity to invest starting just from $10 in investment products that are only available to big institutional players, qualified investors, millionaires, and funds. With the help of the Roobee platform, the goal is to empower people from all over the world to invest in any of the many investment products available on the platform: crypto, stocks, ETFs, IPOs, real estate, and more. To find more information you can visit the platform <a target="blank" href="https://roobee.io/">website</a>.
          </div>
          <div class="info__title info__title--small">What is Roobee.finance?</div>
          <div class="info__about-text">Roobee.Finance is a platform that aims to reward the providers of bROOBEE-BNB liquidity.<br><br>
            <span>Roobee Farm on BurgerSwap (bROOBEE/BNB)</span>
            <ul>
              <li>Tokens allocated for farming: 1,000,000 ROOBEE</li>
              <li>Daily ROOBEE Pool Rewards: 25,000 ROOBEE</li>
              <li>Farming Period: 40 days</li>
            </ul>
            <span>Roobee Farm on BurgerSwap (bROOBEE/BURGER)</span>
            <ul>
              <li>Tokens allocated for farming: 1,000,000 ROOBEE</li>
              <li>Daily ROOBEE Pool Rewards: 25,000 ROOBEE</li>
              <li>Farming Period: 40 days</li>
            </ul>
          </div>
          <div class="info__title info__title--small">How can I buy Roobee (ROOBEE)?</div>
          <div class="info__about-text">You can purchase ROOBEE on a crypto exchange. To see a complete list of crypto exchanges and trading pairs, click on the "Market Pairs" on Coinmarketcap. The ROOBEE token has been traded on major crypto exchanges such as KuCoin, HitBTC, EXMO, BitForex, Liquid, Livecoin, and Yobit since 2019. You can store ROOBEE on an exchange or in your crypto wallet. To read about the platform in more detail, please visit <a target="blank" href="https://roobee.io/">Roobee.io</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "about"
}
</script>
